import { AppContainer, AuthLayout, DashboardLayout } from "common/components";
import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { authRouter } from "./auth.router";
import { dashboardRouter } from "./dashboard.router";

const NotFoundPage = React.lazy(() => import("features/not-found"));
const ForbiddenPage = React.lazy(() => import("features/forbidden"));
const RedirectPage = React.lazy(() => import("features/redirect"));
const ReplayPage = React.lazy(() => import("features/replay"));

const HomePage = React.lazy(() => import("features/home"));
const DockVisualizationPage = React.lazy(() => import("features/dock"));

export const router = createBrowserRouter([
  {
    path: "",
    element: <AppContainer />,
    children: [
      // {
      //   path: "",
      //   element: <HomePage />,
      // },
      // {
      //   path: "/docks/:id",
      //   element: <DockVisualizationPage />,
      // },
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "replays",
        element: <ReplayPage />,
      },
      {
        path: "/dashboard",
        element: <DashboardLayout />,
        children: dashboardRouter,
      },
      {
        path: "/auth",
        element: <AuthLayout />,
        children: authRouter,
      },
      {
        path: "/forbidden",
        element: <ForbiddenPage />,
      },
      {
        path: "/redirect",
        element: <RedirectPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);
