export const FEATURES = {
  //   PORT_DASHBOARD: "PORT_DASHBOARD",
  //   BERTH_DASHBOARD: "BERTH_DASHBOARD",
  //   RECORDING_MANAGEMENT: "RECORDING_MANAGEMENT",
  //   ALARM_MANAGEMENT: "ALARM_MANAGEMENT",
  //   ALARM_SETTING: "ALARM_SETTING",
  //   PORT_INFORMATION: "PORT_INFORMATION",
  //   BERTH_MANAGEMENT: "BERTH_MANAGEMENT",
  //   VESSEL_MANAGEMENT: "VESSEL_MANAGEMENT",
  //   USER_MANAGEMENT: "USER_MANAGEMENT",
  BAS_RECORDING: "BAS_RECORDING",
};
