import { tabletRegExp } from "common/constants/regex.constant";
import { useMediaPredicate } from "react-media-hook";

export const DesktopView = ({ children }) => {
  const isDesktopScreen = useMediaPredicate("(min-width: 1025px)");
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = tabletRegExp.test(userAgent);

  return isDesktopScreen && !isTablet ? children : null;
};
