import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { LanguageSwitcher } from "../index";
import classes from "./page-header.style.module.css";

export const PageHeader = ({ rightComponent, logoRedirectUrl = "#" }) => {
  const { name_en: nameEn, logo_url: logoUrl } = useSelector(
    (state) => state?.organization,
  );

  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center">
        <Box className={classes.logo}>
          <Link to={logoRedirectUrl}>
            <img src={"/images/reecotech-logo.png"} alt="logo" />
          </Link>
        </Box>

        <Box className={classes.pageTitle}>{nameEn}</Box>
      </Box>

      <Box display="flex" alignItems="center">
        {/* <LanguageSwitcher /> */}

        {rightComponent}
      </Box>
    </div>
  );
};
