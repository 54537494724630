const ROLE = {
  ADMIN: 1,
  CONFIGURATOR: 2,
  VIEWER: 3,
};

export const ROLE_LABEL = {
  [ROLE.ADMIN]: "Administrator",
  [ROLE.CONFIGURATOR]: "Configurator",
  [ROLE.VIEWER]: "Viewer",
};

export const roleMatrix = {
  "/": {
    [ROLE.ADMIN]: {
      view: true,
    },
    [ROLE.TECHNICIAN]: {
      view: true,
    },
    [ROLE.USER]: {
      view: true,
    },
  },
  "/dashboard": {
    [ROLE.ADMIN]: {
      view: true,
    },
    [ROLE.TECHNICIAN]: {
      view: true,
    },
    [ROLE.USER]: {
      view: true,
    },
  },
  "/dashboard/account-info": {
    [ROLE.ADMIN]: {
      view: true,
      edit: true,
    },
    [ROLE.TECHNICIAN]: {
      view: true,
      edit: true,
    },
    [ROLE.USER]: {
      view: true,
      edit: true,
    },
  },
  "/dashboard/notifications": {
    [ROLE.ADMIN]: {
      view: true,
      delete: true,
    },
    [ROLE.TECHNICIAN]: {
      view: true,
      delete: true,
    },
    [ROLE.USER]: {
      view: true,
      delete: true,
    },
  },
  "/dashboard/system-notifications": {
    [ROLE.ADMIN]: {
      view: true,
      delete: true,
    },
    [ROLE.TECHNICIAN]: {
      view: false,
      delete: false,
    },
    [ROLE.USER]: {
      view: false,
      delete: false,
    },
  },
  "/dashboard/users": {
    [ROLE.ADMIN]: {
      view: true,
      edit: true,
      delete: true,
      add: true,
    },
    [ROLE.TECHNICIAN]: {
      view: false,
      edit: false,
      delete: false,
      add: false,
    },
    [ROLE.USER]: {
      view: false,
      edit: false,
      delete: false,
      add: false,
    },
  },
  "/dashboard/spms": {
    [ROLE.ADMIN]: {
      view: true,
      edit: true,
      delete: true,
      add: true,
    },
    [ROLE.TECHNICIAN]: {
      view: true,
      edit: true,
      delete: true,
      add: true,
    },
    [ROLE.USER]: {
      view: false,
      edit: false,
      delete: false,
      add: false,
    },
  },
  "/dashboard/detection-devices": {
    [ROLE.ADMIN]: {
      view: true,
      edit: true,
      delete: true,
      add: true,
    },
    [ROLE.TECHNICIAN]: {
      view: false,
      edit: false,
      delete: false,
      add: false,
    },
    [ROLE.USER]: {
      view: false,
      edit: false,
      delete: false,
      add: false,
    },
  },
  "/dashboard/detection-histories": {
    [ROLE.ADMIN]: {
      view: true,
      edit: true,
      delete: true,
      add: true,
    },
    [ROLE.TECHNICIAN]: {
      view: true,
      edit: true,
      delete: true,
      add: true,
    },
    [ROLE.USER]: {
      view: true,
      edit: true,
      delete: true,
      add: true,
    },
  },
  "/dashboard/back-up-data": {
    [ROLE.ADMIN]: {
      view: true,
      delete: true,
      add: true,
    },
    [ROLE.TECHNICIAN]: {
      view: true,
      delete: true,
      add: true,
    },
    [ROLE.USER]: {
      view: false,
      delete: false,
      add: false,
    },
  },
};
