import { usePermission } from "common/hooks";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { menuSections } from "setup/menu";
import classes from "./left-menu.style.module.css";
import { MenuItem } from "./menu-item.component";

export const LeftMenu = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { roleId } = useSelector((state) => state?.user);
  const { hasPermission } = usePermission();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Link to="/dashboard">
          <h1 className={classes.title}>{t("common:app.name")}</h1>
        </Link>
      </div>

      <div className={classes.body}>
        <div className={classes.sections}>
          {menuSections?.map((section, sectionIndex) => (
            <div className={classes.section} key={sectionIndex}>
              <h3 className={classes.sectionTitle}>{t(section?.labelId)}</h3>

              <div className={classes.items}>
                {section?.items
                  ?.filter(
                    (item) =>
                      item?.common === true || hasPermission(item?.feature),
                  )
                  ?.map((item, itemIndex) => (
                    <MenuItem
                      key={itemIndex}
                      label={t(`${item?.labelId}`)}
                      prefixIcon={item?.icon}
                      to={item?.to}
                      isActive={pathname?.startsWith(item?.to)}
                    />
                  ))}
              </div>
            </div>
          ))}
        </div>

        {/* <Link to="/" className={classes.backLink}>
          {t("common:button.back-to-map")}
        </Link> */}
      </div>
    </div>
  );
};
